<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.775" x2="0.156" y2="1.059">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_gift" transform="translate(-375 -2561)">
    <rect :fill="background" data-name="Rectangle 189" height="183" id="Rectangle_189" transform="translate(375 2561)" width="183"/>
    <g data-name="MDI / gift" id="MDI_gift" transform="translate(417.885 2604.349)">
     <g fill="url(#linear-gradient)" id="Boundary" opacity="0" stroke="rgba(0,0,0,0)" stroke-width="1">
      <rect :fill="background" height="97.23" stroke="none" width="97.23"/>
      <rect :fill="background" height="96.23" width="96.23" x="0.5" y="0.5"/>
     </g>
     <path d="M33.653,1.93C26,1.89,18.542,9.263,21.945,18.419H9.1a8.1,8.1,0,0,0-8.1,8.1v8.1a4.051,4.051,0,0,0,4.051,4.051H41.513V26.521h8.1V38.675H86.076a4.051,4.051,0,0,0,4.051-4.051v-8.1a8.1,8.1,0,0,0-8.1-8.1H69.183C73.923,5.171,56.1-4.187,47.873,7.237l-2.309,3.079-2.309-3.16a11.718,11.718,0,0,0-9.6-5.226m-.243,8.386a4.058,4.058,0,1,1-4.051,4.051,4.063,4.063,0,0,1,4.051-4.051m24.308,0a4.058,4.058,0,1,1-4.051,4.051,4.063,4.063,0,0,1,4.051-4.051M5.051,42.726v32.41a8.1,8.1,0,0,0,8.1,8.1h64.82a8.1,8.1,0,0,0,8.1-8.1V42.726H49.615v32.41h-8.1V42.726Z" data-name="Path / gift" fill="url(#linear-gradient)" id="Path_gift" transform="translate(3.051 6.172)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>